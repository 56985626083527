<template>
  <div v-if="trackValidation" class="vx-card p-8 w-full">
      <div class="flex flex-row gap-2 items-center">
          <h5 class="mb-0">{{ $t('admin.tracks.validation.valid') }}</h5>
          <feather-icon v-if="trackValidation.is_valid" icon="CheckIcon" class="text-success align-middle"/>
          <feather-icon v-else icon="XIcon" class="text-danger align-middle"/>
          <p class="mb-0">{{ statusType }}</p>
      </div>

      <div v-if="trackValidation.invalidations.length > 0" class="mt-8">
          <h5>{{ $t('admin.tracks.validation.reasons') }}</h5>
          <ul>
              <li v-for="invalidation in trackValidation.invalidations" :key="invalidation.id_track_type_invalidation">
                  {{ `${invalidation.name} (${invalidation.automated ? $t('admin.tracks.validation.automated') : $t('admin.tracks.validation.manual')}) ${invalidation.description ? invalidation.description : ''}` }}
              </li>
          </ul>
      </div>

      <div v-if="trackValidation.overlaps.length > 0" class="mt-8">
          <h5>{{ $t('admin.tracks.validation.overlaps') }}</h5>
          <Datatable
              :config="datatableConfig"
              :elements="overlaps"
              :stripe="true"
              :sst="true"
          />
      </div>

      <div class="mt-8">
          <h5 class="mb-4">{{ $t('admin.tracks.validation.manual_invalidations') }}</h5>
          <vs-checkbox
              v-for="invalidation in invalidations"
              :key="invalidation.id"
              class="ml-0 mt-2 text-base"
              v-model="invalidation.checked"
          >
              <p class="ml-1 mb-0">{{ invalidation.name }}</p>
          </vs-checkbox>
      </div>

      <div class="mt-8">
          <h5 class="mb-4">{{ $t('admin.tracks.validation.invalidated_challenges') }}</h5>
          <Combobox
              class="mt-2 lg:w-1/3 md:w-2/3 sm:w-full"
              :options="challenges.map(challenge => ({key: challenge.id, text: `${challenge.title} (${challenge.id})`}))"
              :multiple="true"
              v-model="trackValidation.invalidated_challenges"
          />
      </div>

      <div class="flex flex-row gap-4 mt-12">
          <button class="sk-btn sk-btn-primary rounded-lg btn-border" @click="handleCancel">
              {{ $t('apps.modals.cancel')}}
          </button>
          <button class="sk-btn sk-btn-primary rounded-lg" @click="handleSave">
              {{ $t('Save')}}
          </button>
      </div>
  </div>
</template>

<script>

import TrackService from "@/modules/Admin/Services/TrackService";
import Datatable from "@/modules/Shared/Components/table/SKDataTable.vue";
import {DateTime} from "luxon";
import {formatSeconds} from "@/modules/Shared/Helpers/dateHelper";
import ChallengeService from "@/modules/Admin/Services/ChallengeService";
import {unifyPaginatedResponse} from "@/modules/Shared/Helpers/apiResponseHelper";
import PaginationService from "@/modules/Shared/Services/PaginationService";
import Combobox from "@/modules/Shared/Components/Combobox.vue";
import loader from "@/modules/Shared/Mixins/loader";
import store from "@/modules/Shared/Store/store";

export default {
  name: "EditTrackValidationPage",
  components: {Combobox, Datatable},
  mixins: [loader],
  data() {
    return {
      trackValidation: null,
      invalidations: [],
      datatableConfig: [
          { 'type': 'image', 'field': 'thumbnail', head: this.$t("tracks.tracks-list.thumbnail"), 'searchable': false, 'sorteable': false },
          { 'type': 'text', 'field': 'valid', head: this.$t("admin.tracks.validation.valid"), 'searchable': false, 'sortable': false },
          { 'type': 'text', 'field': 'distance', head: this.$t("tracks.tracks-list.distance"), 'searchable': false, 'sorteable': false },
          { 'type': 'text', 'field': 'duration', head: this.$t("tracks.tracks-list.duration"), 'searchable': false, 'sorteable': false },
          { 'type': 'text', 'field': 'startdate', head: this.$t("banner.table.startdate"), 'searchable': false, 'sorteable': false },
          { 'type': 'text', 'field': 'enddate', head: this.$t("banner.table.enddate"), 'searchable': false, 'sorteable': false },
          { 'type': 'text', 'field': 'app', head: this.$t("dashboard.app"), 'searchable': false, 'sorteable': false },
      ]
    }
  },

  async beforeRouteEnter (to, from, next) {
      const trackValidation = await TrackService.getTrackValidation(to.params.uuid)
      await store.dispatch('setRouteElement', trackValidation)
      next(vm => vm.trackValidation = trackValidation)
  },
  computed: {
      statusType() {
          if (this.trackValidation.is_valid) {
              return this.trackValidation.invalidations.length > 0 ? this.$t('admin.tracks.validation.manual') : this.$t('admin.tracks.validation.automated')
          }

          return this.trackValidation.invalidations.some(invalidation => !invalidation.automated) ? this.$t('admin.tracks.validation.manual') : this.$t('admin.tracks.validation.automated')
      },
      overlaps() {
          return this.trackValidation.overlaps.map(overlap => ({
              thumbnail: overlap.thumbnail,
              valid: overlap.is_valid ? '✅' : '❌',
              distance: overlap.statistics.distance ? Math.round(overlap.statistics.distance) + ' m' : '',
              duration: overlap.statistics.duration ? formatSeconds(overlap.statistics.duration) : '',
              startdate: DateTime.fromSeconds(overlap.statistics.timestamp_start).toFormat('dd-LL-yyyy hh:mm:ss'),
              enddate: DateTime.fromSeconds(overlap.statistics.timestamp_end).toFormat('dd-LL-yyyy hh:mm:ss'),
              app: overlap.app.name
          }))
      }
  },

  methods: {
      handleCancel() {
          this.$router.push({ name: 'admin-tracks' });
      },

      async handleSave() {
          await this.loadAndNotify(async () => {
              const invalidations = this.invalidations
                  .filter(invalidation => invalidation.checked)
                  .map(invalidation => invalidation.id)

              const body = {
                  valid: invalidations.length === 0,
                  invalidations: invalidations,
                  invalidated_challenges: this.trackValidation.invalidated_challenges
              }

              try {
                  await TrackService.updateTrackValidation(this.$route.params.uuid, body)
                  await this.$router.push({ name: 'admin-tracks' });
              } catch (error) {
                  console.error(error)
              }
          })
      }
  },

  async created() {
      await this.loadAndNotifyError(async () => {
          const [invalidationTypes, challenges] = await Promise.all([
              TrackService.getInvalidationTypes(),
              unifyPaginatedResponse(ChallengeService.getChallenges, PaginationService.parseParameters({
                  filters: [
                      { field: 'publishend', value: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss') }
                  ],
                  per_page: 30
              }))
          ])

          this.challenges = challenges

          this.invalidations = invalidationTypes.data.map(invalidationType => {
              const invalidation = this.trackValidation.invalidations.find(invalidation => invalidation.id_track_type_invalidation === invalidationType.id)
              return {
                  ...invalidationType,
                  checked: invalidation && !invalidation.automated
              }
          })
      })
  },
};
</script>
