import axios from '@/axios.js'

export default {
    getTracks: async (params, page = 1) => {
        let response = await axios.adminapi.get(`v4/tracks`, {
            params: {
                page: page,
                ...params,
            }
        })
        return response.data.object
    },
    getTrackValidation: async (uuid) => {
        let response = await axios.adminapi.get(`v4/tracks/${uuid}/validation`)
        return response.data.object
    },

    updateTrackValidation: async (uuid, data) => {
        let response = await axios.adminapi.patch(`v4/tracks/${uuid}/validation`, data)
        return response.data.object
    },

    getInvalidationTypes: async (page = 1) => {
        let response = await axios.adminapi.get(`v4/tracks/invalidation-types`,{
            params: {
                page: page,
            }
        })
        return response.data.object
    }
}
